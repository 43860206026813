import React from 'react';
import SEO from '../components/SEO';
import { Divider } from '../components/Divider';

export default function AboutPage() {
  return (
    <>
      <SEO title="About Us" />
      <div>
        <p>
          Hello! Welcome to the "About Us" section of the Huddle House Fan Club
          site. This is the spot to find out who we are, what we do, and why the
          heck we do it.
        </p>
        <p>
          Well it's simple, ok, ok, it isn't simple and it's quite straning for
          the normal human brain to comprehend a bunch of guys and gals going
          off to the Huddle House some weekends just for kicks, but no, it is
          really much more than that. The Huddle House is a place to meet and
          converse and have some darn good food anytime, day or night!
        </p>
        <p>
          It all started because a few of us needed a place to eat after late
          movies, and has since bloomed into the coveted hang out spot that it
          is. There are great people, nice waitresses, and the old regulars you
          bond with over the course of frequent Huddle House visits. And yet, we
          took all that and made an organized group of devoted Huddle House
          fans, thus the Huddle House Fan Club was born.
        </p>
        <p>
          Anyone can be a fan, and to be a regular member all you have to do is
          meet us up there one night and enjoy the magic that is the Huddle
          House. You can't help but enjoy the Huddle House!
        </p>
        <Divider />
        <p>
          Well, although we plan to travel to Huddle House's all over Alabama
          and Georgia, the Huddle House we call home and visit most often is the
          one at 215 Grace Street, Oxford, AL. Yes, 215 Grace Street is graced
          with the first club charter, but we encourage any potenial fans or
          people looking for a great original way to have fun to start their own
          charter. If you do, tell us about it. E-mail us and let us know all
          about it and we might add a section to our site. First one in gets to
          be charter two!
        </p>
        <p>Just remeber have fun, your local Huddle House is your friend!</p>
      </div>
    </>
  );
}
